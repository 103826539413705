<template>
  <!-- 钱包 -->
  <div class="grindBeans">
    <div style="position: relative; padding: 0 10px">
      <div class="topImage">
        <div class="topImageSencod">
          <div class="topImageSencodNb">
            {{ walletBalanceObject.allAmount || "--" }}
          </div>
          <div class="topImageSencodTp">总余额</div>
        </div>
        <div class="topImageSencod">
          <div class="topImageSencodNb">
            {{ walletBalanceObject.freezenAmount || "--" }}
          </div>
          <div class="topImageSencodTp">
            冻结金额
            <van-icon name="question-o" />
          </div>
        </div>
      </div>

      <van-button class="bottomImage" :disabled="btDisable" @click="getWithdrawal">提现</van-button>
    </div>
    <!-- <button @click="ujhytg">提现页面</button> -->
    <div class="mainBottom">
      <div class="mainTopText">
        <div class="lText">余额明细</div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="getWalletBalanceDetaiList">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          finished-text="没有更多了"
        >
          <van-cell
            @click="goWallBeanDetail(item)"
            v-for="(item, index) in BalanceList"
            :key="index"
          >
            <div class="btBoxImage">
              <van-image width="58" height="58" round :src="item.logo" />
            </div>
            <div class="btBoxText">
              <div class="btBoxTextTop">
                <span>{{ item.tradeType }}</span>
                <div>{{ item.changeTime }}</div>
              </div>
              <div>
                <!-- curAmount现有余额 -->
                <div class="btBoxTextBottom">
                  {{ item.chgAmount.includes('-')? item.chgAmount :'+' + item.chgAmount}}
                </div>
                <div style="font-size: 12px; text-align: right; color: #999999">
                  余额:{{ item.curAmount }}
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { setUserBlankCarId } from "@/untils/localStorage";

import { Notify } from "vant";
import moment from "moment";
import {
  getWalletBalance,
  getWalletBalanceDetaiList,
  getWalletBalanceCard,
} from "@/api/myWalletBeans/myWalletBeans";
export default {
  name: "myWalletBeans",
  computed: {
    BIZ_USERID() {
      return this.$route.query.userToken || "";
    },
  },
  data() {
    return {
      ImageSrc: {
        image: require("../../assets/auditingState/jindutiao-qiandai.png"),
      },
      walletBalanceObject: {
        allAmount: "0.00",
        freezenAmount: "0.00",
      },
      BalanceList: [],
      loading: false,
      btDisable:false,
      finished: true,
      refreshing: true,
      pageNum: 1, // 初始化页码为1
    };
  },
  created() {
    this.getWalletBalance();
    this.getWalletBalanceDetaiList()
  },
  mounted() {},
  methods: {
    // 测试按钮
    ujhytg(){
      // this.$router.push({name:'sucessWithdrawalChange'})
      this.$router.push({name:'myWalletBeansDetail'})
    },
  
    // 获取钱包明细列表
    getWalletBalance() {
      // 先写死   BIZ_USERID
      getWalletBalance(this.BIZ_USERID)
        .then((res) => {
          if (res.data.code == "10000" && res.data.subCode == "OK") {
            Object.assign(this.walletBalanceObject, JSON.parse(res.data.data));
          } else {
            Notify({ type: "warning", message: res.data.subMsg || res.data.msg });
          }
        })
        .catch((error) => {
          Notify({ type: "warning", message: error });
          return error;
        });
    },
    // 余额明细
    getWalletBalanceDetaiList() {
      this.finished = false;
      this.refreshing = true
        let dateStart = moment(new Date())
        .subtract(30, "days")
        .format("YYYY-MM-DD HH:mm:ss"); // 起始时间
      let dateEnd = moment(new Date()).format("YYYY-MM-DD HH:mm:ss"); // 结束时间
   
      // 这边的bizUserId不传，后端从token里面拿取
      getWalletBalanceDetaiList(this.BIZ_USERID, dateStart, dateEnd, this.pageNum)
        .then((res) => {
          if (res.data.code == "OK") {
              this.BalanceList = res.data.inExpDetail;
              this.finished = false;
              this.loading = false;
              this.refreshing = false
          }else{
            this.finished = false;
            this.loading = false;
            this.refreshing = false
            Notify({ type: "warning", message: res.msg });
          }
        })
        .catch((error) => {
          // 结束刷新或加载更多状态
          this.finished = true;
          this.loading = false;
          this.refreshing = false
          return error;
        });
    },
    // 条状详情余额页面
    goWallBeanDetail(item) {
      if(item.type !== '分账' ) return
      this.$router.push({ name: "myWalletBeansDetail", query:{objectDetail:item,token:this.BIZ_USERID}} )
    },
    onLoad() {
      this.loading = false
      this.finished = true
    },
    // 提现
    getWithdrawal() {
      this.btDisable = true
      // 掉检验银行卡是否绑定接口，然后成功跳转
      // this.BIZ_USERID
      getWalletBalanceCard(this.BIZ_USERID).then((res) => {
          // 储存银行卡号
          switch (res.data.code) {
            case 200:
              // 已经绑卡继续提交操作
              // 储存下银行卡号
              this.$tools.setTimeMiao(()=>{
              this.btDisable = false
              },3000) 
              setUserBlankCarId(res.data?.data[0] || '')
              this.$router.push({name:'withdrawalBank',query:{balanceDetails:this.walletBalanceObject,token:this.BIZ_USERID}})
           
              break;
            case 500:
              // 未绑卡跳转到绑卡页面
              this.$tools.setTimeMiao(()=>{
              this.btDisable = false
              },3000) 
              Notify({ type: "warning", message: "未绑定银行卡，请先绑定银行卡" });
              this.$router.push({name:'bankCardDeatil',query:{userToken:this.BIZ_USERID}})
              break;
            // 可以继续添加其他case
            default:
              // 默认情况下执行的代码块
              Notify({ type: "warning", message: "接口异常，请重试" });
              this.$tools.setTimeMiao(()=>{
              this.btDisable = false
              },3000) 
          }
        })
        .catch((error) => {
          this.$tools.setTimeMiao(()=>{
              this.btDisable = false
              },3000) 
          Notify({ type: "warning", message: error });
     
        });
    }
  },
  destroyed(){
    // 销毁token
  }
};
</script>

<style scoped lang="scss">
.topImage {
  display: flex;
  justify-content: space-between;
  text-align: center; /* 文字水平居中 */
  background-color: #ffffff;
  background-image: url("../../assets/钱包底图.png");
  background-size: cover; /* 保持背景图片尺寸与容器相同 */
  background-repeat: no-repeat; /* 设置背景图片不平铺 */
  color: #ffffff;
  height: 178px;
  padding: 0 40px;
  .topImageSencod {
    display: inline-block;
    width: 30%;
    margin-top: 60px;
    .topImageSencodNb{
      font-size: 24px;
    }
  }
  .topImageSencodTp {
    font-size: 14px !important;
    color: #ffffffbb;
    margin-bottom: 10px;
  }
  .type {
    margin-top: 10px;
    display: inline-block;
    width: 80px;
    font-size: 16px;
    border: none;
    border-radius: 40px;
    height: 30px;
    color: #ff9513;
    background-color: #ffffff;
  }
}
.bottomImage {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 10px;
  width: 86px;
  height: 33px;
  color: #ff861c;
  border-radius: 30px;
  box-shadow: 0px 2px 4px 0px #ff9933;
}
/* 在这里添加媒体查询 */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .topImage {
    background-image: url("../../assets/钱包底图@2x.png"); /* 使用2x图像 */
  }
}
.grindBeans {
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}
.mainBottom {
  margin: 10px 10px 0 10px;
  .van-cell__value {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
}
.mainTopText {
  display: flex;
  height: 56px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 56px;
  background: linear-gradient(to bottom, #ff8d0b34, #fffafc);
  justify-content: space-between;
  align-content: center;
  box-sizing: border-box;
  .lText {
    font-size: 18px;
    font-weight: 600;
  }

  .zNabav {
    font-weight: 500;
  }
}
.btBoxtopText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  .btBoxImage {
    flex: 1;
  }
}
.btList {
  margin: 0 10px;
}
.btBoxText {
  flex: 3;
  display: flex;
  padding: 0 20px;
  height: 76px;
  border-bottom: 1px solid #e1e0e0;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  .btBoxTextTop {
    color: #222222;
    font-size: 14px;
    font-weight: 400;
    div {
      // margin-top: 10px;
      color: #999999;
      font-size: 12px;
    }
  }
  .btBoxTextBottom {
    color: #f8a452;
    font-size: 18px;
  }
}
</style>
